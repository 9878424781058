import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class KanbanService {
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  getKanban(id) {
    return axiosService.axiosIns.get(`${this.jwtConfig.getKanban}/${id}`)
  }

  getAllKanban() {
    return axiosService.axiosIns.get(`${this.jwtConfig.getAllKanban}`)
  }

  getAllKanbanForCalendar() {
    return axiosService.axiosIns.get(`${this.jwtConfig.getAllKanbanForCalendar}`)
  }

  addTaskKanban(task) {
    return axiosService.axiosIns.post(`${this.jwtConfig.addTaskKanban}/${task.idKanban}/`, { ...task })
  }

  updateTask(ids, task) {
    return axiosService.axiosIns.post(`${this.jwtConfig.updateTask}/${ids.id}/${ids.idTask}`, { ...task })
  }

  deleteTask(ids) {
    return axiosService.axiosIns.post(`${this.jwtConfig.deleteTask}/${ids.id}/${ids.idTask}`)
  }

  createKanban(kanban) {
    return axiosService.axiosIns.post(`${this.jwtConfig.createKanban}/`, { ...kanban })
  }
}

function useKanbanService() {
  const kanbanService = new KanbanService()

  return {
    kanbanService,
  }
}

const { kanbanService } = useKanbanService()
export default kanbanService
