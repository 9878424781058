import axiosIns from '@axios'
import kanbanService from '@core/services/kanban/kanbanService'
/* eslint-disable */ 
const colors = [
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
  'white',
  'black',
  'dark',
  'light',
]

function createColorizedArray(originalArray, colors) {
  const colorizedArray = originalArray.map((item, index) => {
    const colorIndex = index % colors.length // Cycle through colors array
    return {
      color: colors[colorIndex],
      label: item.label,
      id: item.idKanban,
    }
  })
  return colorizedArray
}

function createSimpleArray(originalArray) {
  return originalArray.map(item => ({
    label: item.label,
    id: item.idKanban,
  }))
}

function createCalendarsColor(colorizedArray) {
  return colorizedArray.reduce((acc, curr) => {
    const { label, color } = curr;
    acc[label] = color;
    return acc;
  }, {});
}

export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    selectedCalendars: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      console.log(val)
      state.selectedCalendars = val
    },
  },
  actions: {    
    fetchEvents(ctx) {
      return new Promise((resolve, reject) => {
        kanbanService.getAllKanbanForCalendar()
          .then(response => {
            const { kanbanList } = response.data
            ctx.state.calendarOptions = createColorizedArray(kanbanList, colors);
            ctx.state.selectedCalendars = createSimpleArray(kanbanList);
            response.data.colors = createCalendarsColor(ctx.state.calendarOptions)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /* eslint-enable */
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
